import React from 'react';
import './borders.css';
export default function Borders() {
  return (
    <>
      <div class="border-container d-none d-lg-block">
        <div class="box1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="174"
            height="230"
            viewBox="0 0 174 230"
            fill="none"
          >
            <path
              d="M39.4008 228.601L0.691737 115.041L48.293 1.53642L134.437 1.10353L173.146 114.663L125.545 228.168L39.4008 228.601Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div class="box2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="253"
            height="333"
            viewBox="0 0 253 333"
            fill="none"
          >
            <path
              d="M57.2886 332.533L0.886452 167.067L70.2979 1.33563L196.029 0.825629L252.431 166.291L183.102 332.266L57.2886 332.533Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div class="box3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="333"
            height="438"
            viewBox="0 0 333 438"
            fill="none"
          >
            <path
              d="M75.2561 436.711L1.0777 219.096L92.2995 1.13778L257.617 0.55063L331.795 218.166L240.573 436.124L75.2561 436.711Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div class="box4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="379"
            height="493"
            viewBox="0 0 379 493"
            fill="none"
          >
            <path
              d="M60.2243 491.89L-31.7304 222.126L81.3016 -48.0594L286.205 -48.7236L378.16 221.041L265.128 491.226L60.2243 491.89Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div class="box5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="410"
            height="518"
            viewBox="0 0 410 518"
            fill="none"
          >
            <path
              d="M29.031 517.206L-80.6999 195.292L54.3082 -127.262L298.964 -128.144L408.695 193.77L273.687 516.323L29.031 517.206Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="470"
            height="568"
            viewBox="0 0 470 568"
            fill="none"
          >
            <path
              d="M-33.1134 567.316L-178.314 141.346L0.314793 -285.66L324.144 -286.697L469.344 139.272L290.715 566.279L-33.1134 567.316Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="501"
            height="593"
            viewBox="0 0 501 593"
            fill="none"
          >
            <path
              d="M-64.1457 592.495L-227.122 114.376L-26.6837 -364.858L336.732 -365.972L499.709 112.147L299.27 591.381L-64.1457 592.495Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box8 d-none d-lg-block">
          <div></div>
        </div>
        <div className="box9 d-none d-lg-block">
          <div></div>
        </div>
      </div>

      <div class="border-container-2 d-none d-lg-block">
        <div class="box10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="165"
            height="242"
            viewBox="0 0 165 242"
            fill="none"
          >
            <path
              d="M41.5957 0.724243H123.195L163.819 120.815L123.195 240.905H41.5957L0.971499 120.815L41.5957 0.724243Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box11">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="240"
            height="352"
            viewBox="0 0 240 352"
            fill="none"
          >
            <path
              d="M60.4714 0.598816H179.367L238.728 175.818L179.367 351.037H60.4714L0.935843 175.818L60.4714 0.598816Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="315"
            height="462"
            viewBox="0 0 315 462"
            fill="none"
          >
            <path
              d="M79.172 0.467163H235.54L313.637 230.814L235.54 461.162H79.172L1.07538 230.814L79.172 0.467163Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box13">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="389"
            height="573"
            viewBox="0 0 389 573"
            fill="none"
          >
            <path
              d="M97.8719 1.33606H291.713L388.545 286.812L291.713 572.287H97.8719L1.03907 286.812L97.8719 1.33606Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="box14">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="464"
            height="684"
            viewBox="0 0 464 684"
            fill="none"
          >
            <path
              d="M116.572 0.915771H347.885L463.454 341.818L347.885 682.72H116.572L1.00346 341.818L116.572 0.915771Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        {/* <div className="box15">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="488"
            height="794"
            viewBox="0 0 488 794"
            fill="none"
          >
            <path
              d="M135.273 0.784058H404.058L538.364 396.814L404.058 792.845H135.273L0.967858 396.814L135.273 0.784058Z"
              stroke="#C2C2C2"
              stroke-width="0.8"
              stroke-miterlimit="10"
            />
          </svg>
        </div> */}
      </div>
    </>
  );
}

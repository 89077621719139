import React from 'react';
import './home.css';
import Borders from '../Borders/Borders';
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight
} from '@react-hook/window-size';
export default function Home() {
  const [width, height] = useWindowSize();

  return (
    <>

      <section className="homeSection ">
        {width > 767 ? <Borders /> : ''}

        <div className="container-fluid ">
          <div className="row">
            <div className="col">
              <p className="headingText">
              Career Raccoon Entry Level Consultancy in all Field
              We Provide Jobs in Pan-India 
              </p>
              <p className="subHeading">
                Our mission is to bridge the gap between talented candidates and thriving companies, ensuring a seamless hiring process and exceptional training support.
              </p>
              <div className="d-flex justify-content-center pt-4">
              <a className="readDocs" href='https://wa.me/919340093083' target="_blank">
           WhatApp me
            </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container  pt-5 pb-2  ">
          <h1 className="text-center fw-bold techHeading py-5 ">
            About us
          </h1>
          <div className="row gap-0  gap-md-4 sub-section   justify-content-around">
            <div className="col-11 mb-3 mb-md-0 col-md-3 sub-section-div ">
              <h5 className='sub-headingH5'>Candidate Placement</h5>
              <p className="py-2 sub-headingP">Career Raccoon takes the hassle out of recruitment by sourcing, screening, and placing qualified candidates in your organization.</p>
            </div>
            <div className="col-11 mb-3 mb-md-0 col-md-3  sub-section-div">
              <h5 className='sub-headingH5'>Comprehensive Training</h5>
              <p className="py-2 sub-headingP">Our comprehensive training programs equip candidates with the skills they need to excel in their new roles.</p>
            </div>
            <div className="col-11  mb-3 mb-md-0 col-md-3 sub-section-div">
              <h5 className='sub-headingH5'>Business Operations</h5>
              <p className="py-2 sub-headingP"> From administrative support to specialized roles, our candidates are ready to contribute to your business’s success.</p>
            </div>
          </div>
        </div>


        <div className="container py-5">
          <div className="row d-flex justify-content-center">
            <h1 className="text-center fw-bold techHeading py-5 ">
              Why Choose Career Raccoon?
            </h1>
          </div>
        </div>

        <div className="container-fluid py-5">
          <div className="row ">
            <div className="col-12 col-md-6  d-flex justify-content-end">
              <img src="./assets/gif1.gif" alt="gif" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center px-5">
              <div>
                <p className="ezeePos">Expertise</p>
                <p className="py-2">
                  With years of experience in the outsourcing industry, we understand the nuances of different business sectors and can provide tailored solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-5">
          <div className="row ">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-end px-5">
              <div>
                <p className="ezeePos">Quality Assurance</p>
                <p className="py-2">
                  Our rigorous vetting process ensures that only the most qualified candidates are presented to you.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6  d-flex justify-content-start">
              <img src="./assets/gif2.gif" alt="gif" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="container-fluid py-5">
          <div className="row ">
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <img src="./assets/gif3.gif" alt="gif" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-start px-5">
              <div>
                <p className="ezeePos">Efficiency & Support</p>
                <p className="py-2">
                  We streamline the hiring process, saving you time and resources. And Our training and development programs ensure that your new hires are not only ready to start but also positioned to grow within your company.
                </p>

              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col embr">
              <div className="box-8 d-none d-lg-block">
                <div></div>
              </div>
              <div className="box-9 d-none d-lg-block">
                <div></div>
              </div>
              <h1 className="embrance">
                Get Started with Career Raccoon
              </h1>
              <p className="  leadingCharge z-3 ">
                Ready to find the perfect candidates for your business? Contact Career Raccoon today to learn more about our outsourcing solutions and how we can help you build a stronger, more efficient team.
              </p>
              <div className="d-flex justify-content-center">
            <a className="readDocs" href='https://wa.me/919340093083'  target="_blank">
           Enquire Now
            </a>
            
                {/* Conetact us Section */}
              </div>
            </div>
          </div>
        </div>
        
        <hr />
      </section>

     
    </>
  );
}
